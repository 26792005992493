import logo from './logo.svg';
import './App.css';
import React, { useContext, useEffect, useState, useRef } from "react";
import { Space, Table, Spin, Tooltip } from 'antd';
import { Avatar, List, DatePicker, TimePicker,Button, Input, Typography } from 'antd';
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

dayjs.extend(duration)
const { Paragraph } = Typography;


function App() {
  const [data, setData] = useState([]);
  const [timestamp, setTimestamp] = useState([]);
  const {RangePicker} = DatePicker;
  const [dates, setDates] = useState([dayjs().subtract(1, 'hours'), dayjs()]);
  const [isLoading, setIsLoading] = useState(false);
  const [robotId, setRobotId] = useState("");
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [evtFilter, setEvtFilter] = useState([]);
  const [rbtFilter, setRbtFilter] = useState([]);
  const [stateFilter, setStateFilter] = useState([]);
  const [dateTime, setDateTime] = useState(null); // 用於儲存選擇的日期和時間
  const handleChange = (date, dateString) => {
    setDateTime(date); // 設定選擇的日期和時間
};

  


  const getReports =  async (e) => {
    console.log('getReports: ', dates);
    const reports = [];

    var s_date = dates[0].set('seconds', 0).set('millisecond', 0).toDate();
    //s_date.set('seconds', 0).set('millisecond', 0);
    var s_date_iso = s_date.toISOString();

    var e_date = dates[1].set('seconds', 59).set('millisecond', 999).toDate();
    //e_date.set('seconds', 59).set('millisecond', 999);
    var e_date_iso = e_date.toISOString();
    
    const params = new URLSearchParams();
    params.append('start', s_date_iso);
    params.append('end', e_date_iso);
    var index = robotId.replaceAll(' ', '');
    index = index.replaceAll(';', ',');
    index = index.toLowerCase();
    params.append('index', index);

    //const url = 'http://127.0.0.1:5001/restore-0705/us-central1/webApi/api/v1/robots?' + params.toString();
    //const url = 'https://us-central1-restore-0705.cloudfunctions.net/webApi/api/v1/robots?' + params.toString();
    const url = 'https://api.dev2.ur-srobot.com/auditSrv/v1/jrls?' + params.toString();
    //const url = 'http://127.0.0.1:5001/m2-dev-24wk21/us-central1/auditSrv/auditSrv/v1/jrls?' + params.toString();
    console.log("url:", url);

    setIsLoading(true);
    setData([]);
    fetch(url,
    {method:'GET',
      headers:{
        'content-type':'application/text',
        "Content-Security-Policy": "upgrade-insecure-requests",
        "Authorization": "Bearer urs82884839"
      }
    })
    .then(res => {
        if (!res.ok) {
          setIsLoading(true);
          alert("No data found"); 
          throw new Error(`HTTP error: ${res.status}`);
        }
        return res.text();   // 使用 text() 可以得到純文字 String
    }).then(result => {
        
        var rawdata = JSON.parse(result);
        //console.log("rawdata", rawdata);

        setTimestamp(new Date().toLocaleString());
        var logs = [];
        var i = rawdata.length;
        var events = new Set();
        var states = new Set();
        var robots = new Set();
        rawdata.forEach((raw) => {
          const {_id, ...rest} = raw;
          var data = {id: i--, ...rest};
          if(data.car_id.startsWith("0000")){
            data.car_id = "p_" + data.car_id.slice(10);
          }else if(data.car_id.startsWith("6490")){
            data.car_id = "q_" + data.car_id.slice(10);
          }
          events.add(data.event);
          states.add(data.state);
          robots.add(data.car_id)
          logs.push({id: i--, server_time:data.timestamp, timestamp: data.robot_timestamp, robot_id: data.car_id, event: data.event, state: data.state, meta: data.meta});
        });
        console.log(robots);
        console.log(events);
        console.log(states);
        // "_id": "2024-07-13T02:04:48.758Z_052",
        // "meta": "1.000000",
        // "index": "9f92",
        // "robot_timestamp": "20240713100448",
        // "state": "True",
        // "event": "LE_Pct",
        // "car_id": "0000000041619f92",
        // "timestamp": "2024-07-13T02:04:48.826Z"

        setData(logs);
        let events_types = [...events].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setEvtFilter(events_types);
        let robots_types = [...robots].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setRbtFilter(robots_types);
        let states_types = [...states].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setStateFilter(states_types);

        setIsLoading(false);
    })
    .catch(err => {
      console.error(err);
      setIsLoading(false);
    });
  }

  const set10mins =  (e) => {
    let s_date = dayjs().subtract(10, 'minutes');
    let e_date = dayjs();
    console.log(s_date, e_date);
    setDates([s_date, e_date]);
  }
  const set30mins =  (e) => {
    let s_date = dayjs().subtract(30, 'minutes');
    let e_date = dayjs();
    console.log(s_date, e_date);
    setDates([s_date, e_date]);
  }

  const inputRobotId =  (e) => {
    setRobotId(e.target.value);
  }

  useEffect(()=>{

  },[]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    confirm();  //Henry: Add
    setSearchedColumn(dataIndex);//Henry: Add
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters,selectedKeys, confirm, dataIndex) }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true })
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'server_t',
      dataIndex: 'server_time',
      key: 'server_time',
      fixed: 'left',
      width: 85,
      ...getColumnSearchProps('server_time'),
    },
    {
      title: 'robot_t',
      dataIndex: 'timestamp',
      key: 'timestamp',
      fixed: 'left',
      width: 85,
      ...getColumnSearchProps('timestamp'),
    },
    {
      title: 'rbt_id',
      dataIndex: 'robot_id',
      key: 'robot_id',
      width: 75,
      filters:rbtFilter,
      onFilter: (value, record) => record.robot_id===value,
    },
    {
      title: 'event',
      dataIndex: 'event',
      key: 'event',
      width: 75,
      filters:evtFilter,
      onFilter: (value, record) => record.event===value,
    },
    {
      title: 'state',
      dataIndex: 'state',
      key: 'state',
      width: 75,
      filters:stateFilter,
      onFilter: (value, record) => record.state===value,
    },
    {
      title: 'meta',
      dataIndex: 'meta',
      key: 'meta',
      ...getColumnSearchProps('meta'),
    },
  ];
  return (
    <div className="App">
      <p className="report_search_desc" >Nexmow Cloud Journal</p> 
      <div className = "report-search">
        {/* <TimePicker.RangePicker 
          minuteStep={15}
          showNow="true"
          format="YYYY-MM-DD HH:mm"
          defaultValue={dates}
          value={dates}
          size="small" 
          onChange={ (dates, dateStrings) =>  setDates(dates)}
        /> */}
        <DatePicker.RangePicker
                showTime // 允許選擇時間
                value={dateTime} // 顯示當前選擇的日期和時間
                minuteStep={15}
                defaultValue={dates}
                value={dates}
                size="small" 
                onChange={ (dates, dateStrings) =>  setDates(dates)} // 設定選擇的回調
                format="YYYY-MM-DD HH:mm" // 日期和時間的格式
            />
        <Button htmlType="submit" onClick={async (e) => await getReports(e) } loading={isLoading} >
          Query
        </Button>
      </div>
      <div className = "report-search">
        <Button htmlType="submit" onClick={async (e) => set10mins()} >
          Set last 10 mins
        </Button>
        <Button htmlType="submit" onClick={async (e) => set30mins()} >
          Set last 30 mins
        </Button>
      </div>
      <div class="box">
        {/* <div>
          RobotId to search( ex: 52d2;4392 )
        </div> */}
        <div>
          <Input placeholder="RobotId to search (ex: 34ea,9f92)" style={{ width: 250 }} onChange={inputRobotId}/>
        </div>
      </div>
        <Table 
        className="generaltable"
        showHeader = "true"
        loading={isLoading}
        title = {(currentPageData) => {
          return `Number of filtered data: ${data.length}`;
          //return `<p className="main-title">Robots list()</p>`
        }}
        bordered="true"
        size="small" 
        dataSource={data} 
        columns={columns} 
        pagination={{ showQuickJumper: true, position: ['topCenter', 'bottomCenter'], defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['25', '50', '100', '500']}} 
        //scroll={{ x: 500}}
        scroll={{ y: 500, x: 500 }} // 設置垂直滾動和水平滾動
        // sticky={true}
        />
    </div>
  );
}

export default App;
